// src/Login.js
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom'; // Ensure Link is imported
import './styles.css';
import backgroundImage from './assets/images/loginbg.webp';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate(); //corrected to useNavigate

  const styles = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log('Submitting login for email:', email);
      const response = await axios.post('https://www.rclcheck.com/login', { email, password });
      console.log('Response from backend:', response.data);
      if (response.data.success) {
        // Store the token in local storage or state
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('is_admin', response.data.is_admin); //Store admin status
        localStorage.setItem('user_email', response.data.email); //Store user email
        console.log('Logged in as:',response.data.email);
        console.log('Token is:',response.data.token);
        console.log('Admin status:', response.data.is_admin);
        navigate('/dashboard'); //corrected to use navigate
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error('Login error', error);
      alert('An error occurred. Please try again.');
    }
  };

  return (
    <div style={styles} className="login-page">
      <div className="overlay"></div>
      <div className="login-container">
        <h1>Login</h1>
        <form onSubmit={handleSubmit}>
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <label>Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <button type="submit">Login</button>
        </form>
        <p>
          Don't have an account? <Link to="/register">Register here</Link>
        </p>
      </div>
      <footer className="login-footer">
        <p>
        <a href="/user-agreement">User Agreement</a> 
        |
        <a href="/privacy-policy">Privacy Policy</a> 
        |
        <a href="/contact-us">Contact Us</a>
        <br></br>
        © 2024 Mobius Technologies
        </p>
        </footer>
    </div>
  );
};

export default Login;
