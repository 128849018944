// src/Admin.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Admin = () => {
    console.log('Admin component rendered')
    const navigate = useNavigate();
    const handleDashboardButtonClick = () => {
        navigate('/dashboard');
      };
    const [pendingUsers, setPendingUsers] = useState([]);
    const [activeUsers, setActiveUsers] = useState([]);

  useEffect(() => {
    fetchPendingUsers();
    fetchActiveUsers();
    const userEmail = localStorage.getItem('user_email');
    const isAdmin = localStorage.getItem('is_admin');
    console.log('Logged in as:', userEmail);
    console.log('Is admin:', isAdmin);
  }, []);

  const fetchPendingUsers = async () => {
    try {
        const token = localStorage.getItem('token');
        const response = await axios.get('https://www.rclcheck.com/admin/pending-users', {
            headers: {
                'x-access-token': token //send token in the header
            }
        });
      setPendingUsers(response.data.users);
    } catch (error) {
      console.error('Error fetching pending users:', error);
    }
  };

  const fetchActiveUsers = async () => {
    try {
      const token = localStorage.getItem('token');
      console.log('Fetching active users with token:', token); 
      const response = await axios.get('https://www.rclcheck.com/admin/active-users', {
        headers: {
          'x-access-token': token 
        }
      });
      console.log('Active users response:', response.data); 
      if (response.data.success) {
        setActiveUsers(response.data.users);
      } else {
        console.error('Failed to fetch active users:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching active users:', error);
    }
  };

  const handleApprove = async (userId) => {
    try {
        const token = localStorage.getItem('token');  
        await axios.post('https://www.rclcheck.com/admin/approve-user', { userId }, {
            headers: {
              'x-access-token': token // Send token in the header
            }
          });
        fetchPendingUsers();
    } catch (error) {
      console.error('Error approving user:', error);
    }
  };

  const handleReject = async (userId) => {
    try {
        const token = localStorage.getItem('token');
        await axios.post('https://www.rclcheck.com/admin/reject-user', { userId }, {
          headers: {
            'x-access-token': token // Send token in the header
          }
        });
      fetchPendingUsers();
    } catch (error) {
      console.error('Error rejecting user:', error);
    }
  };

  const handleDelete = async (userId, userEmail) => {
    const confirmDelete = window.confirm(`Are you sure you wish to delete user ${userEmail}?`);
    if (confirmDelete) {
      try {
        const token = localStorage.getItem('token');
        await axios.post('https://www.rclcheck.com/admin/delete-user', { userId }, {
          headers: {
            'x-access-token': token 
          }
        });
        fetchActiveUsers();
      } catch (error) {
        console.error('Error deleting user:', error);
      }
    }
  };
  

  return (
    <div className="admin-container">
      <h1>Back to Dashboard</h1>
      <button onClick={() => navigate('/dashboard')} style={{ marginTop: '0px' }}>Back to Dashboard</button>
      
      <hr /> {/* Horizontal line separator */}
      
      <h1>Pending User Approvals</h1>
      <ul>
        {pendingUsers.map(user => (
          <li key={user.id}>
            {user.email}
            <button onClick={() => handleApprove(user.id)} style={{ marginLeft: '10px' }}>Approve</button>
            <button onClick={() => handleReject(user.id)} style={{ marginLeft: '10px' }}>Reject</button>
          </li>
        ))}
      </ul>
   
      <hr /> {/* Horizontal line separator */}

      <h1>Active Users</h1>
      <ul>
        {activeUsers.map(user => (
          <li key={user.id}>
            {user.email}
            <button onClick={() => handleDelete(user.id)} style={{ marginLeft: '10px' }}>Delete</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Admin;
