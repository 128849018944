import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './Login';
import Home from './Home';
import Dashboard from './Dashboard';
import Register from './Register';
import Admin from './Admin';
import Sidebar from './Sidebar';
import ProtectedRoute from './ProtectedRoute';
import './styles.css';

const App = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  return (
    <Router>
      <div className={`app ${isSidebarOpen ? '' : 'sidebar-collapsed'}`}>
        <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
        <button className="menu-btn" onClick={toggleSidebar}>☰</button>
        <div className="main-content">
          <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route element={<ProtectedRoute />}>
                <Route path="/" element={<Home />} />
                <Route path="/dashboard" element={<Dashboard />} />
              </Route>
              <Route element={<ProtectedRoute requireAdmin={true} />}>
                <Route path="/admin" element={<Admin />} />
              </Route>
            </Routes>
        </div>
      </div>
    </Router>
  );
};

export default App;


