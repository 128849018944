import React from 'react';
import { useNavigate } from 'react-router-dom';
import './styles.css'; // Import the CSS file

const Home = () => {
  const navigate = useNavigate();
  const userEmail = localStorage.getItem('user_email');

  const handleDashboardClick = () => {
    navigate('/dashboard');
  };

  const handleProfileClick = () => {
    navigate('/profile');
  };

  const handleSettingsClick = () => {
    navigate('/settings');
  };

  return (
    <div className="home-container">
      <h1>Welcome, {userEmail}!</h1>
      <p>Welcome to your account! Here you can navigate to various parts of your application.</p>
      <button onClick={handleDashboardClick}>Go to Dashboard</button>
      <button onClick={handleProfileClick} style={{ marginLeft: '10px' }}>Go to Profile</button>
      <button onClick={handleSettingsClick} style={{ marginLeft: '10px' }}>Go to Settings</button>
    </div>
  );
};

export default Home;
