// src/Dashboard.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './styles.css';

const Dashboard = () => {
  const navigate = useNavigate();
  const isAdmin = localStorage.getItem('is_admin') === '1'; // Check admin status
  const email = localStorage.getItem('user_email');
  console.log('Dashboard component rendered');
  console.log('Admin status:', isAdmin);
  console.log('Logged in as:', email);
  
  const handleAdminButtonClick = () => {
    navigate('/admin');
  };

  const handleHomeButtonClick = () => {
    navigate('/'); // Navigate to the root URL
  };

  const handleLogout = () => {
    localStorage.clear(); // Clear all localStorage items
    navigate('/login'); // Navigate back to login page
  };

  return (
    <div className="dashboard-container">
      <h1>Welcome to the Dashboard!</h1>
      {isAdmin && (
        <button onClick={handleAdminButtonClick}>
          Go to Admin Page
        </button>
      )}
      <br />
      <br />
      <button onClick={handleHomeButtonClick}> Go to Home Page </button>
      <br />
      <button onClick={handleLogout} style={{ marginTop: '20px' }}>Logout</button>
      {/* Other dashboard content */}
    </div>
  );
};

export default Dashboard;
