import React from 'react';
import './Sidebar.css';

const Sidebar = ({ isOpen, toggleSidebar }) => {
  return (
    <div className={`sidebar ${isOpen ? '' : 'collapsed'}`}>
      <button className="close-btn" onClick={toggleSidebar}>X</button>
      <nav>
        <ul>
          <li><a href="/"><i className="icon-home"></i><span>Home</span></a></li>
          <li><a href="/dashboard"><i className="icon-dashboard"></i><span>Dashboard</span></a></li>
          <li><a href="/dashboard"><i className="icon-dashboard"></i><span>ASHRAE 15</span></a></li>
          <li><a href="/dashboard"><i className="icon-dashboard"></i><span>ASHRAE 15.2</span></a></li>
          <li><a href="/admin"><i className="icon-admin"></i><span>Admin</span></a></li>
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
